/** @jsx jsx */
import { jsx, Flex, Themed } from 'theme-ui';
import { getContentImageSize } from '@sprinklr/shared-lib/utils/getContentImageSize';
import { Container, ImageBlock } from '@sprinklr/shared-lib';
import { useContainerPadding } from '@sprinklr/shared-lib/hooks';
import { HighlightedText } from '@sprinklr/shared-lib/components/higlightedText';
import { ReactElement } from 'react';
import { Image } from '@sprinklr/shared-lib/@types/entities';
import { SectionTitleStyles } from './types';

export default function ContentTitle({
  icon,
  headlineText,
  subHeadlineText,
  titleStyles,
}: {
  icon?: { asset: Image };
  headlineText: string;
  subHeadlineText?: string;
  titleStyles?: SectionTitleStyles;
}): ReactElement {
  const {
    iconSize,
    headlineSize,
    subHeadlineSize,
    contentAlign,
    topPadding,
    bottomPadding,
    paddingBelowIcon,
    shouldLimitHeadlineMaxWidth,
    shouldLimitSubHeadMaxWidth,
    color,
  } = titleStyles || {};

  const top = topPadding ? topPadding : 'NONE';
  const bottom = bottomPadding ? bottomPadding : 'NONE';

  const [, imageMarginBottom] = useContainerPadding({
    top: 'NONE',
    bottom: paddingBelowIcon ?? 'SMALL',
  });
  imageMarginBottom[0] = 30;
  const [pt, pb] = useContainerPadding({ top, bottom });
  if (bottom === 'SMALL') pb[0] = 20;
  const Headline = headlineSize ? Themed[headlineSize] : Themed.h2;
  const SubHeadline = subHeadlineSize ? Themed[subHeadlineSize] : Themed.h6;
  const imageWidth = getContentImageSize(iconSize, true);

  const image = icon?.asset;
  const iconSx = { width: imageWidth, mb: imageMarginBottom };

  return (
    <Container fullHeight>
      <Flex
        sx={{
          flexDirection: 'column',
          alignItems: contentAlign ? contentAlign : 'center',
          textAlign: contentAlign ? contentAlign : 'center',
          pt,
          pb,
        }}
      >
        {image && <ImageBlock image={image} imageSx={iconSx} svgSx={iconSx} />}
        {headlineText && (
          <Headline
            sx={{
              maxWidth: shouldLimitHeadlineMaxWidth ? '900px' : '100%',
              color: color,
            }}
          >
            <HighlightedText as="span">{headlineText}</HighlightedText>
          </Headline>
        )}
        {subHeadlineText && (
          <SubHeadline
            sx={{
              fontWeight: 'regular',
              maxWidth: shouldLimitSubHeadMaxWidth ? '800px' : '100%',
              color: color,
            }}
          >
            {subHeadlineText}
          </SubHeadline>
        )}
      </Flex>
    </Container>
  );
}
