export enum Variant {
  NONE = 'NONE',
  SMALL = 'SMALL',
  MEDIUM = 'MEDIUM',
  LARGE = 'LARGE',
}

export const useContainerPadding = ({ top, bottom }) => {
  const paddingMap = {
    [Variant.NONE]: [0, 0],
    [Variant.SMALL]: ['16px', null, null, '32px'],
    [Variant.MEDIUM]: ['32px', null, null, '60px'],
    [Variant.LARGE]: ['48px', null, null, '100px'],
  };

  return [paddingMap[top], paddingMap[bottom]];
};
