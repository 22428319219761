import { BackgroundImage } from '@sprinklr/shared-lib';
import React from 'react';

import { Box } from 'theme-ui';
import ContentTitle from '../../templates/contentTitleTemplate';
import TemplateRenderer from '../tempateRenderer/TemplateRenderer';
import CategoryLabel from './components/CategoryLabel';

import { useContainerPadding, Variant } from './hooks/useContainerPadding';
import { RichTextObject } from '@sprinklr/shared-lib/@types/entities';

// TODO: Add more comprehensive types
type Props = {
  templates: any[];
  backgroundColor?: string;
  paddingTop: string;
  paddingBottom: string;
  sectionTitle: {
    headlineText: string;
    subHeadlineText: string;
    icon: any;
  };
  sectionTitleStyles: any;
  backgroundImage?: any;
  backgroundImageMobile?: any;
  categoryLabelText?: RichTextObject;
};

const LayoutSectionV2 = ({
  templates,
  backgroundColor,
  paddingTop,
  paddingBottom,
  sectionTitle,
  sectionTitleStyles,
  categoryLabelText,
  backgroundImage,
  backgroundImageMobile,
}: Props) => {
  const padding = {
    top: paddingTop ?? Variant.MEDIUM,
    bottom: paddingBottom ?? Variant.MEDIUM,
  };
  const [pt, pb] = useContainerPadding(padding);

  return (
    <Box
      sx={{
        backgroundColor,
        pt,
        pb,
        px: ['16px', '32px'],
        display: 'flex',
        justifyContent: 'center',
        position: 'relative',
      }}
    >
      <BackgroundImage
        image={backgroundImage}
        mobileImage={backgroundImageMobile ?? backgroundImage}
      />
      <Box sx={{ maxWidth: '1040px', width: '100%' }}>
        {categoryLabelText && (
          <CategoryLabel categoryLabel={categoryLabelText} />
        )}
        {sectionTitle && (
          <ContentTitle {...sectionTitle} titleStyles={sectionTitleStyles} />
        )}
        <TemplateRenderer templates={templates} />
      </Box>
    </Box>
  );
};

export default LayoutSectionV2;
