import React from 'react';
import { ThemeProvider, ThemeUIStyleObject } from 'theme-ui';

import { RichText } from '@sprinklr/shared-lib';
import { RichTextObject } from '@sprinklr/shared-lib/@types/entities';

const HEADLINE_STYLES: ThemeUIStyleObject = {
  color: 'pageType',
  fontSize: [1, 2],
  letterSpacing: '1.2px',
  mt: ['10px', 0],
  fontFamily: 'body',
  fontWeight: 'bold',
  marginBottom: [3, '20px'],
  textTransform: 'uppercase',
  textAlign: 'center',
};

const CategoryLabel = ({
  categoryLabel,
}: {
  categoryLabel: RichTextObject;
}) => {
  return (
    <ThemeProvider
      theme={{
        styles: {
          h1: HEADLINE_STYLES,
          h2: HEADLINE_STYLES,
          p: HEADLINE_STYLES,
        },
      }}
    >
      <RichText richTextObject={categoryLabel} />
    </ThemeProvider>
  );
};

export default CategoryLabel;
